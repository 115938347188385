import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient, private _userService: UserService) {}

  public get jwtToken(): string {
    return this._userService.userSubjectValue?.token;
  }

  signIn(credentials: { email: string; password: string }) {
    return this.http.post<any>(`${environment.apiURL}auth/adminsignin`, credentials, { withCredentials: true }).pipe(
      map((user) => {
        this._userService.user = user;
        this.startRefreshTokenTimer();
        return user;
      })
    );
  }

  signOut() {
    this.http
      .post<any>(`${environment.apiURL}auth/revokeToken`, {})
      .subscribe(/*() => this._cookieService.delete('refreshToken')*/);
    this.stopRefreshTokenTimer();
    this._userService.user = null;
  }

  refreshToken() {
    return this.http.post<any>(`${environment.apiURL}auth/refreshToken`, {}, { withCredentials: true }).pipe(
      map((user) => {
        this._userService.user = user;
        this.startRefreshTokenTimer();
        return user;
      })
    );
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}

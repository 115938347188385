/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'users',
    title: 'Users',
    type: 'basic',
    icon: 'heroicons_outline:users',
    link: '/users',
  },
  {
    id: 'blog',
    title: 'Blogs',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/blogs',
  },
  {
    id: 'supportRequests',
    title: 'Support Requests',
    type: 'basic',
    icon: 'heroicons_outline:support',
    link: '/supportRequests',
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];

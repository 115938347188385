import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { appInitializer, ErrorInterceptor, JwtInterceptor } from './helpers';
import {
  AuthService,
  BlogsService,
  CustomSnackbarService,
  LamieninsService,
  PageService,
  SupportService,
  UserService,
} from './services';

@NgModule({
  imports: [MatSnackBarModule, IconsModule, TranslocoCoreModule],
  providers: [
    AuthService,
    UserService,
    CustomSnackbarService,
    LamieninsService,
    PageService,
    BlogsService,
    SupportService,
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { NameInitialsPipe } from './pipes/name-initials.pipe copy';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, DateAgoPipe, NameInitialsPipe],
  declarations: [DateAgoPipe, NameInitialsPipe],
})
export class SharedModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Page } from '../models';

export interface PagesApiResponse {
  data: Page[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private generateApiUrl = (path: string) => environment.apiURL + 'page/' + path;

  private _page: BehaviorSubject<Page> = new BehaviorSubject<Page>(null);
  _pages: BehaviorSubject<PagesApiResponse> = new BehaviorSubject<PagesApiResponse>(null);
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get page$(): Observable<Page> {
    return this._page.asObservable();
  }

  get pageAsObj(): Page {
    return this._page.value;
  }

  get pages$(): Observable<PagesApiResponse> {
    return this._pages.asObservable();
  }

  getPage(id: string): Observable<Page> {
    return this._httpClient.get<Page>(environment.apiURL + 'page/single/' + id).pipe(
      tap((response) => {
        this._page.next(response);
      })
    );
  }

  getAllPages(data: {
    pageNo: number;
    size: number;
    sortBy: string;
    sortDirection: string;
    name?: string;
    email?: string;
    regdate?: string;
    role?: string;
  }): Observable<PagesApiResponse> {
    return this._httpClient
      .get<PagesApiResponse>(this.generateApiUrl('fetchPagesAdmin'), {
        params: data,
      })
      .pipe(
        map((response) => {
          this._pages.next(response);
          return response;
        })
      );
  }
}

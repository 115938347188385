import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/core/models/user.model';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _forgotPassToken: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  set user(value: any) {
    this.userSubject.next(value);
  }

  get forgotPassToken$(): Observable<string> {
    return this._forgotPassToken.asObservable();
  }

  public get user$(): Observable<User> {
    return of(this.userSubject.value?.user);
  }

  public get userObj(): User {
    return this.userSubject.value?.user;
  }

  get userSubjectValue() {
    return this.userSubject.value;
  }

  get userSubject$(): Observable<any> {
    return this.userSubject.asObservable();
  }

  getCurrentUser(): Observable<User> {
    return this.userSubject.pipe(
      take(1),
      switchMap((userDetailsAfterSignin: any) =>
        this._httpClient.get<{ user: User }>(environment.apiURL + 'users/me').pipe(
          map((currentUser: any) => {
            userDetailsAfterSignin.user = currentUser.user;

            this.userSubject.next(userDetailsAfterSignin);

            return currentUser.user;
          })
        )
      )
    );
  }

  changePassword(data: { oldPassword: string; newPassword: string }) {
    return this._httpClient.post<{ message: string }>(environment.apiURL + 'users/changePassword', data);
  }

  forgotPassSendOtp(data: { email: string }) {
    return this._httpClient
      .post<{ message: string; token?: string }>(environment.apiURL + 'users/forgotPass/sendOtp', data)
      .pipe(
        tap((response) => {
          if (response.token) {
            this._forgotPassToken.next(response.token);
          }
          return response;
        })
      );
  }

  forgotPassReSendOtp(token: string) {
    return this._httpClient.get<any>(environment.apiURL + 'users/forgotPass/resendOtp', {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }

  forgotPassVerifyOtp(value: string, token: string) {
    return this._httpClient.post<any>(
      environment.apiURL + 'users/forgotPass/verifyOtp',
      { value },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  }

  forgotPassChangePass(password: string, token: string) {
    return this._httpClient.post<any>(environment.apiURL + 'users/forgotPass/changePass', password, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }

  resetForgotPassToken() {
    this._forgotPassToken.next(null);
  }

  sendBulkMail(data: { subject: string; mailBody: string; isSendAll: boolean; userIds: string[] }) {
    return this._httpClient.post<any>(environment.apiURL + 'users/sendBulkMail', data);
  }
}
